import classnames from 'classnames';
import Image from 'next/image';
import React, { useMemo } from 'react';
import { useInView } from 'react-intersection-observer';

import { EdgeShadowsOverlay } from '../../components/backdrops/EdgeShadowsOverlay';
import SecondaryLink from '../../components/buttons/SecondaryLink';
import { StrapiApiPlugin } from '../../lib/integrations/strapi/types';
import pageRoutes from '../../router/pageRoutes';

const CARD_WIDTH = 160;
const CARD_HEIGHT = 60;

type AllIntegrationsShowcaseProps = {
  plugins: StrapiApiPlugin[];
};
const AllIntegrationsShowcase: React.FC<AllIntegrationsShowcaseProps> = React.memo((props) => {
  const { plugins } = props;

  const { firstSegmentPlugins, secondSegmentPlugins } = useMemo(() => {
    const pluginsPerRow = Math.floor(plugins.length / 2);
    return {
      firstSegmentPlugins: plugins.slice(0, pluginsPerRow),
      secondSegmentPlugins: plugins.slice(1 * pluginsPerRow, 1 * pluginsPerRow + pluginsPerRow),
    };
  }, [plugins]);

  return (
    <EdgeShadowsOverlay shadowWidth={200} shadowHeight={1000} className="max-w-screen-2xl mx-auto md:mt-[100px] relative] z-0">
      <IntegrationCardsAnimatedPanel
        plugins={firstSegmentPlugins}
        inViewClassName="translate-x-[20%] opacity-100 hidden md:block"
        outOfViewClassName="translate-x-[140%] opacity-0 hidden md:block"
        firstRowClassName="ml-[50px]"
      />
      <div className="my-24 flex flex-col items-center gap-4 text-pureWhite">
        <h1 className="text-center leading-none">Full spectrum integrations</h1>
        <p className="text-center ">Transform your everyday applications with Generative AI</p>
        <SecondaryLink mode="dark" href={pageRoutes.Integrations}>
          See all integrations
        </SecondaryLink>
      </div>
      <IntegrationCardsAnimatedPanel
        plugins={secondSegmentPlugins}
        inViewClassName="-translate-x-[10%] opacity-100 hidden md:block"
        outOfViewClassName="-translate-x-[120%] opacity-0 hidden md:block"
        secondRowClassName="ml-[50px]"
      />
    </EdgeShadowsOverlay>
  );
});
AllIntegrationsShowcase.displayName = 'AllIntegrationsShowcase';
export default AllIntegrationsShowcase;

/************************************************************************************************
 * Helpers
 ************************************************************************************************/

type IntegrationCardsAnimatedPanelProps = {
  plugins: StrapiApiPlugin[];
  inViewClassName: string;
  outOfViewClassName: string;
  firstRowClassName?: string;
  secondRowClassName?: string;
};
const IntegrationCardsAnimatedPanel: React.FC<IntegrationCardsAnimatedPanelProps> = React.memo((props) => {
  const { plugins, firstRowClassName, secondRowClassName } = props;
  const { inViewClassName, outOfViewClassName } = props;
  const pluginsPerRow = Math.floor(plugins.length / 2);
  const { ref: containerRef, inView } = useInView({ threshold: 0.5, triggerOnce: true });
  return (
    <div ref={containerRef}>
      <div
        style={{ transition: 'opacity 1000ms ease-in-out, transform 500ms ease-in-out' }}
        className={classnames('opacity-0', { [inViewClassName]: inView, [outOfViewClassName]: !inView })}
      >
        <IntegrationCardsRow plugins={plugins.slice(0, pluginsPerRow)} className={firstRowClassName} />
        <IntegrationCardsRow plugins={plugins.slice(pluginsPerRow)} className={secondRowClassName} />
      </div>
    </div>
  );
});
IntegrationCardsAnimatedPanel.displayName = 'IntegrationCardsAnimatedPanel';

type IntegrationCardsRowProps = {
  plugins: StrapiApiPlugin[];
  className?: string;
};
const IntegrationCardsRow: React.FC<IntegrationCardsRowProps> = React.memo((props) => {
  const { plugins, className } = props;
  return (
    <ul className={classnames('flex flex-row mb-2 gap-2', className)}>
      {plugins.map(({ id, attributes }) => (
        <li
          key={`integration-${id}`}
          style={{ width: `${CARD_WIDTH}px`, height: `${CARD_HEIGHT}px` }}
          className={classnames('flex flex-row rounded-3xl justify-center items-center border-xl bg-pureWhite/20 select-none')}
        >
          <Image className="grayscale" width={CARD_WIDTH - 70} height={CARD_HEIGHT - 70} src={attributes.logoUrl} alt={attributes.name} />
        </li>
      ))}
    </ul>
  );
});
IntegrationCardsRow.displayName = 'IntegrationCardsRow';
