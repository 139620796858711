import classnames from 'classnames';
import React from 'react';

type EdgeShadowsOverlayProps = {
  className?: string;
  shadowWidth: number;
  shadowHeight: number;
  children: React.ReactNode;
};
export const EdgeShadowsOverlay: React.FC<EdgeShadowsOverlayProps> = React.memo((props) => {
  const { className, shadowWidth, shadowHeight, children } = props;
  const shadowStyle = { width: shadowWidth, height: shadowHeight };
  return (
    <div className={classnames('relative overflow-hidden', className)}>
      <SideShadow direction="left" className="hidden md:block" style={shadowStyle} />
      <SideShadow direction="right" className="hidden md:block" style={shadowStyle} />
      {children}
    </div>
  );
});
EdgeShadowsOverlay.displayName = 'EdgeShadowsOverlay';

/************************************************************************************************
 * Helpers
 ************************************************************************************************/

type SideShadowProps = {
  style: React.CSSProperties;
  className?: string;
  direction: 'left' | 'right';
};
const SideShadow: React.FC<SideShadowProps> = React.memo((props) => {
  const { className, style, direction } = props;
  return (
    <div
      style={style}
      className={classnames(className, 'absolute z-10 top-0', {
        'left-0 bg-gradient-to-l from-pureWhite/0 to-black/100': direction === 'right',
        'right-0 bg-gradient-to-r from-pureWhite/0 to-black/100': direction === 'left',
      })}
    />
  );
});
SideShadow.displayName = 'SideShadow';
