import Image from 'next/image';
import React from 'react';

import Segmented from '../../components/controls/Segmented';

export enum SegmentOption {
  Slack = 'Slack',
  Teams = 'Teams',
  JIRA = 'JIRA',
}
type PlatformShowcaseProps = {
  images: { [key in SegmentOption]: string };
};
const PlatformShowcase: React.FC<PlatformShowcaseProps> = React.memo((props) => {
  const { images } = props;
  const [selectedOption, setSelectedOption] = React.useState<string>(SegmentOption.Slack);
  return (
    <div className="flex flex-col gap-4 items-center justify-center max-w-[900px] mx-auto">
      <h1 className="text-center leading-none">Seamless employee experience across platforms</h1>
      <p>
        Automate tasks and solve employee helpdesk issues instantly with our Generative AI Virtual Agent. It integrates right where
        employees are for an effortless support experience: on Slack, MS Teams and Jira SM support portal.
      </p>
      <div className="h-auto md:h-[480px]">
        {selectedOption === SegmentOption.Slack && (
          <Image
            className="rounded-xl float-end animate-fadeIn"
            width={800}
            height={500}
            src={images[SegmentOption.Slack]}
            alt={`${selectedOption} Showcase`}
          />
        )}
        {selectedOption === SegmentOption.Teams && (
          <Image
            className="rounded-xl float-end animate-fadeIn"
            width={800}
            height={500}
            src={images[SegmentOption.Teams]}
            alt="Teams Showcase"
          />
        )}
        {selectedOption === SegmentOption.JIRA && (
          <Image
            className="rounded-xl float-end animate-fadeIn"
            width={800}
            height={500}
            src={images[SegmentOption.JIRA]}
            alt="JIRA Showcase"
          />
        )}
      </div>
      <Segmented
        buttonsClassName="py-3 px-6 md:py-3 md:px-16"
        nonSelectedClassName="text-pureWhite bg-[#2b5ba4]"
        selectedClassNames={{
          [SegmentOption.Slack]: 'text-pureWhite bg-purple',
          [SegmentOption.Teams]: 'text-pureWhite bg-purple',
          [SegmentOption.JIRA]: 'text-pureWhite bg-purple',
        }}
        options={[SegmentOption.Slack, SegmentOption.Teams, SegmentOption.JIRA]}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
      />
    </div>
  );
});
PlatformShowcase.displayName = 'PlatformShowcase';
export default PlatformShowcase;
