import classnames from 'classnames';
import Link, { LinkProps } from 'next/link';
import React from 'react';

const DOT_SIZE = 20;
const DOT_INNER_SIZE = 8;
const DOT_BORDER_SIZE = 3;

type SecondaryLinkProps = React.HTMLProps<HTMLAnchorElement> & {
  mode: 'light' | 'dark';
};
const SecondaryLink: React.FC<SecondaryLinkProps> = React.memo((props) => {
  const { children, mode, className, ...restProps } = props;
  const [isHovered, setIsHovered] = React.useState(false);
  return (
    <Link
      {...(restProps as LinkProps)}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className={classnames(
        'select-none cursor-pointer flex items-center text-xl gap-2 font-semibold',
        {
          'text-purple': mode === 'light',
          'text-pureWhite': mode === 'dark',
        },
        className,
      )}
    >
      <HoverableDot isHovered={isHovered} mode={mode} />
      {children}
    </Link>
  );
});
SecondaryLink.displayName = 'SecondaryLink';
export default SecondaryLink;

/************************************************************************************************
 * Helpers
 ************************************************************************************************/

type HoverableDotProps = {
  isHovered: boolean;
  mode: 'light' | 'dark';
};
const HoverableDot: React.FC<HoverableDotProps> = React.memo((props) => {
  const { mode, isHovered } = props;
  return (
    <span
      style={{ width: DOT_SIZE, height: DOT_SIZE, borderWidth: DOT_BORDER_SIZE }}
      className={classnames('rounded-full flex items-center justify-center', {
        'border-purple': mode === 'light',
        'border-pureWhite': mode === 'dark',
      })}
    >
      <span
        style={{ width: DOT_INNER_SIZE, height: DOT_INNER_SIZE }}
        className={classnames('inline-block rounded-full transition-all duration-100', {
          'bg-purple': isHovered,
          'bg-transparent': !isHovered,
        })}
      />
    </span>
  );
});
HoverableDot.displayName = 'HoverableDot';
