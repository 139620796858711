import { def } from '@gaspardesk/utils';
import classnames from 'classnames';
import Image from 'next/image';
import React from 'react';

import SecondaryLink from '../../buttons/SecondaryLink';

export type AlternateLayoutListItem = {
  title: string;
  description?: string;
  orientation: 'left' | 'right';
  imageUrl: string;
  destinationUrl?: { label: string; href: string };
};

type AlternateLayoutListProps = {
  items: AlternateLayoutListItem[];
};
const AlternateLayoutList: React.FC<AlternateLayoutListProps> = React.memo((props) => {
  const { items } = props;
  return (
    <div className="flex flex-col gap-12">
      {items.map((item, index) => (
        <React.Fragment key={`alternate-layout-list-item-${index}`}>
          <ListEntryRenderer key={index} data={item} />
          {index < items.length - 1 && (
            <div className="w-full flex items-center justify-center sm:hidden md:flex">
              <div className="my-8 h-[2px] w-full max-w-6xl bg-lightPurple/50"></div>
            </div>
          )}
        </React.Fragment>
      ))}
    </div>
  );
});
AlternateLayoutList.displayName = 'AlternateLayoutList';
export default AlternateLayoutList;

/************************************************************************************************
 * Helpers
 ************************************************************************************************/

type ListEntryRendererProps = {
  data: AlternateLayoutListItem;
};
const ListEntryRenderer: React.FC<ListEntryRendererProps> = React.memo((props) => {
  const { title, description, orientation, imageUrl, destinationUrl } = props.data;
  return (
    <div
      className={classnames('flex gap-6 justify-center items-center flex-col-reverse', {
        'md:flex-row-reverse': orientation === 'right',
        'md:flex-row': orientation === 'left',
      })}
    >
      <div className="flex flex-col gap-4 max-w-[600px]">
        <h2 className="leading-none">{title}</h2>
        {def(description) && <p>{description}</p>}
        {def(destinationUrl) && (
          <SecondaryLink mode="light" href={destinationUrl.href}>
            {destinationUrl.label}
          </SecondaryLink>
        )}
      </div>
      <Image className="rounded-xl float-end" width={400} height={400} src={imageUrl} alt={title} />
    </div>
  );
});
ListEntryRenderer.displayName = 'ListEntryRenderer';
